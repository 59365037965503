

export type tSnippet = {
    name: string;
    path: string;
    imageUrl: string;
    snippet: string;
    section: 'gas' | 'meter-boxes' 
    subsection: 'prepayment' | 'smartmeter' | 'dumbgasmeter' | 'gas-semi-concealed' | 'gas-cavity-box' | 'gas-multi-box' | 'gas-surface-box' | ''
  };
  
  

export const snippets: tSnippet[] = [
    {
        name: 'Uniflo Smart Gas Meter',
        path: '/gas/prepayment/uniflo-smart-gas-meter',
        imageUrl: '/images/meters/Flonidan-Gas-Meter.jpg',
        snippet: 'The Flonidan Uniflo Smart Gas Meter is a great meter which comes with lots of great functionality, even though it might not look as advanced as say a Landis & Gyr 470 or an Elster smart meter.  It can be used as a pre-payment meter.',
        section: 'gas',
        subsection: 'prepayment'
    },
    {
        name: 'Landis+Gyr Libra 110 Gas Meter',
        path: '/gas/prepayment/libra-110',
        imageUrl: '/images/meters/Libra110.jpg',
        snippet: 'This is a nifty little pre-payment gas meter, much like its cousin the 210, but somehow more stylish and certainly seems to breakdown much less!',
        section: 'gas',
        subsection: 'prepayment'
      },
      {
        name: 'Landis & Gyr G350 Gas Meter',
        path: '/gas/prepayment/landis-and-gyr-350',
        imageUrl: '/images/meters/LandisGyr350.jpg',
        snippet: 'A smart meter that packs a punch.  Look, when it comes to this meter this is a lot of technical stuff I could say about the different communication methods it has but, all you need to know is it can talk to its compatible electric meter, which in turn updates your in-home display.',
        section: 'gas',
        subsection: 'prepayment'
      },
      {
        name: 'Landis And Gyr 470',
        path: '/gas/prepayment/landis-and-gyr-470',
        imageUrl: '/images/meters/LandisAndGyr470.jpg',
        snippet: 'The Landis & Gyr G470 gas meter is a second-generation smart meter, essentially an upgrade on the G370.  Stylish and simple to use this meter promises to be around for some time.',
        section: 'gas',
        subsection: 'prepayment'
      },
      {
        name: 'Landis & Gyr 370 Series Gas Meter',
        path: '/gas/prepayment/landis-and-gyr-370',
        imageUrl: '/images/meters/LandisAndGyr370.png',
        snippet: 'The G370 series is a modular solution that satisfies the UK Smart metering requirements. A G370 module can be quickly retrofitted to existing L+G meters to upgrade economically with Smart capabilities. Landis & Gyr G370 provides for Credit mode, Prepayment mode (although rarely used by suppliers), AMI and Over the Air programmability with the proven E6V solid-state metrology.',
        section: 'gas',
        subsection: 'prepayment'
      },
      {
        name: 'Landis & Gyr 210 Series Gas Meter',
        path: '/gas/prepayment/landis-and-gyr-210',
        imageUrl: '/images/meters/LandisAndGyr210.png',
        snippet: 'The Libra 210 module provides the UK industry-standard method of gas pre-payment functionality through a secure Smart Card system. When fitted to a meter the module will account for gas consumption on a block tariff supporting up to 6 blocks, recover debt, provide controlled emergency credit and provides tariff updates through the POS system.',
        section: 'gas',
        subsection: 'prepayment'
      },
      {
        name: 'SciFlo Smart Gas Meter',
        path: '/gas/smartmeter/sciflow-smart-gas-meter',
        imageUrl: '/images/meters/SciFlo-Smart-Gas-Meter.jpg',
        snippet: 'The SciFlo Smart Gas Meter from Flonidan is a great little smart meter.  Housed in steel this meter is designed for residential use, is lightweight, with a great battery lifespan for the digital display screen.',
        section: 'gas',
        subsection: 'smartmeter'
      },
      {
        name: 'Uniflo Smart Gas Meter',
        path: '/gas/smartmeter/uniflo-smart-gas-meter',
        imageUrl: '/images/meters/Flonidan-Gas-Meter.jpg',
        snippet: 'The Flonidan Uniflo Smart Gas Meter is a great meter which comes with lots of great functionality, even though it might not look as advanced as say a Landis & Gyr 470 or an Elster smart meter.  It can be used as a pre-payment meter.',
        section: 'gas',
        subsection: 'smartmeter'
    },
    {
      name: 'Landis & Gyr G350 Gas Meter',
      path: '/gas/smartmeter/landis-and-gyr-350',
      imageUrl: '/images/meters/LandisGyr350.jpg',
      snippet: 'A smart meter that packs a punch.  Look, when it comes to this meter this is a lot of technical stuff I could say about the different communication methods it has but, all you need to know is it can talk to its compatible electric meter, which in turn updates your in-home display.',
      section: 'gas',
      subsection: 'smartmeter'
    },
    {
      name: 'Landis And Gyr 470',
      path: '/gas/smartmeter/landis-and-gyr-470',
      imageUrl: '/images/meters/LandisAndGyr470.jpg',
      snippet: 'The Landis & Gyr G470 gas meter is a second-generation smart meter, essentially an upgrade on the G370.  Stylish and simple to use this meter promises to be around for some time.',
      section: 'gas',
      subsection: 'smartmeter'
    },
    {
      name: 'Landis & Gyr 370 Series Gas Meter',
      path: '/gas/smartmeter/landis-and-gyr-370',
      imageUrl: '/images/meters/LandisAndGyr370.png',
      snippet: 'The G370 series is a modular solution that satisfies the UK Smart metering requirements. A G370 module can be quickly retrofitted to existing L+G meters to upgrade economically with Smart capabilities. Landis & Gyr G370 provides for Credit mode, Prepayment mode (although rarely used by suppliers), AMI and Over the Air programmability with the proven E6V solid-state metrology.',
      section: 'gas',
      subsection: 'smartmeter'
    },
    {
      name: 'Elster Themis-Alpha Smart Gas Meter',
      path: '/gas/smartmeter/elster-themis-alpha',
      imageUrl: '/images/meters/Elster.jpg',
      snippet: 'The Themis-Alpha gas meter produced by Honeywell was the smart meter of choice for E.on and SSE when it was released.',
      section: 'gas',
      subsection: 'smartmeter'
    },
    {
      name: 'BOBi G4 Gas Meter',
      path: '/gas/dumbgasmeter/bobi',
      imageUrl: '/images/meters/BOBi.jpg',
      snippet: 'The BOBi G4 Diaphragm Gas Meter is a dumb gas meter made by Meters UK. It is a fairly industry-standard gas meter made from aluminium. I would estimate the lifespan of this meter to be up to 20 years.',
      section: 'gas',
      subsection: 'dumbgasmeter'
    },
    {
      name: 'QK 2000 Dumb Gas Meter',
      path: '/gas/dumbgasmeter/qk2000',
      imageUrl: '/images/meters/qk2000.jpg',
      snippet: 'The QK 2000 Dumb Gas Meter is aluminium gas meter, which is lightweight and produces very little noise (some gas meters can start to hum or create a rattling noise).  The QK 2000 has a lifespan of around 12 years, which is a shorter than the 20-year lifespan you get with something like a Sensus U6 meter.',
      section: 'gas',
      subsection: 'dumbgasmeter'
    },
    {
      name: 'Siemens E6 Gas Meter',
      path: '/gas/dumbgasmeter/siemens-e6',
      imageUrl: '/images/meters/British-Gas-Meter.jpg',
      snippet: 'The Siemens E6 Gas Meter first started to be used in the UK around 1993, it is a dumb meter, but with a more modern digital display readout.  The meter is around the same size as a Landis & Gyr meter.',
      section: 'gas',
      subsection: 'dumbgasmeter'
    },
    {
      name: 'G4 Diaphragm Gas Meter',
      path: '/gas/dumbgasmeter/g4diaphragm',
      imageUrl: '/images/meters/G4Dia.jpg',
      snippet: 'The G4 Diaphragm Gas Meter is a very basic dumb gas meter.  In the same vein as the Sensus U6 meter this is a sturdy gas meter with at least a twenty-year life span.',
      section: 'gas',
      subsection: 'dumbgasmeter'
    },
    {
      name: 'Landis & Gyr G130 Gas Meter',
      path: '/gas/dumbgasmeter/landis-and-gyr-g130',
      imageUrl: '/images/meters/g130.png',
      snippet: 'The Landis & Gyr G130 is a small electronic gas meter with a digital backlit display, internal shut-off valve, factory fitted battery (minimum 10-year life, compared to the 20-year battery life of the Elster smart meter) and 1″ BS 746 connections.',
      section: 'gas',
      subsection: 'dumbgasmeter'
    },
    {
      name: 'Nuovo Pignone',
      path: '/gas/dumbgasmeter/nuovo-pignone',
      imageUrl: '/images/meters/NuovoPignone.jpg',
      snippet: 'The Nuovo Pignone is a hardy dumb gas meter.  Smaller than the more popular Sensus dumb meter, the Nuovo Pignone is almost just as robust a reliable.',
      section: 'gas',
      subsection: 'dumbgasmeter'
    },
    {
      name: 'Sensus U6',
      path: '/gas/dumbgasmeter/sensusu6',
      imageUrl: '/images/meters/SensusU6.png',
      snippet: 'U6 SENSUS (Cubix) U6 OFGEM Approved domestic gas meter is designed for measurement of gas supplied to residential, commercial and industrial premises where maximum consumption of gas is equivalent to 6m3/h of air of density of 1,2 kg/m3.',
      section: 'gas',
      subsection: 'dumbgasmeter'
    },
    {
      name: 'Surface Box',
      path: '/meter-boxes/gas-surface-box/surfacebox',
      imageUrl: '/images/boxes/SurfaceBox.jpg',
      snippet: 'Firstly, not really sure why these are called surface boxes. They don’t sit on a surface. Very much like a gas meter cavity box, this box will be attached to the side of your property.',
      section: 'meter-boxes',
      subsection: 'gas-surface-box'
    },
    {
      name: 'Gas Meter Multibox',
      path: '/meter-boxes/gas-multi-box/multibox',
      imageUrl: '/images/boxes/Multibox.jpg',
      snippet: 'The multibox is a ground box, so it sits on the ground. Unlike a gas meter cavity box or a surface box which are attached to the wall, and raised off the floor. The multibox, is very much like a unibox.',
      section: 'meter-boxes',
      subsection: 'gas-multi-box'
    },
    {
      name:'Gas Meter Unibox',
      path: '/meter-boxes/gas-multi-box/unibox',
      imageUrl: '/images/boxes/Unibox.jpg',
      snippet: 'The gas meter unibox is a gas meter box that sits on the floor. So rather than a gas meter cavity box, or a surface box both of which are on the wall lifted off the floor, the unibox is sat on the floor.',
      section: 'meter-boxes',
      subsection: 'gas-multi-box'
    },
    {
      name: 'Gas Meter Cavity Box',
      path: '/meter-boxes/gas-cavity-box/gas-cavity-box',
      imageUrl: '/images/boxes/GasCavity.jpg',
      snippet: 'Having a gas meter within a cavity box is very common. A cavity box is simply a box that sits within a cavity in the wall of your home.',
      section: 'meter-boxes',
      subsection: 'gas-cavity-box'
    },
    {
      name: 'Mitras Connect Semi-Concealed Meter Box',
      path: '/meter-boxes/gas-semi-concealed/mitras-connect',
      imageUrl: '/images/boxes/Mitras-Connect.jpg',
      snippet: 'The Mitras Connect Semi-Concealed Meter Box is probably the semi-concealed gas box that most gas engineers would prefer to use. I mean, they would prefer not to use a semi-concealed box at all, but if they had to choose one it would be this.',
      section: 'meter-boxes',
      subsection: 'gas-semi-concealed'
    },
    {
      name: 'Mitras Semi-Concealed Meter Box',
      path: '/meter-boxes/gas-semi-concealed/mitras',
      imageUrl: '/images/boxes/Mitras.jpg',
      snippet: 'The Mitras Semi-Concealed Meter Box is a ground gas meter box that is very strong. You are pretty much certain that if you get a call out to repair a damaged Mitras Semi-Concealed Meter Box then it was probably damaged by a car running over it, twice.',
      section: 'meter-boxes',
      subsection: 'gas-semi-concealed'
    },
    {
      name: 'National Grid Semi-Concealed Meter Box',
      path: '/meter-boxes/gas-semi-concealed/national-grid',
      imageUrl: '/images/boxes/National-Grid.jpg',
      snippet: 'The National Grid Semi-concealed meter box is a ground box for a gas meter. It suffers from many of the issues that ground boxes suffer from. If water gets inside from the rain it might not drain, and water can come up from the ground.',
      section: 'meter-boxes',
      subsection: 'gas-semi-concealed'
    },
    {
      name: 'IGT Semi-Concealed Meter Box',
      path: '/meter-boxes/gas-semi-concealed/igt',
      imageUrl: '/images/boxes/IGT.jpg',
      snippet: 'The IGT (Independent Gas Transporters) Semi-Concealed Meter Box was a very common box for newbuild houses through the 1990’s – 2000’s with the boom of independent gas transporters.',
      section: 'meter-boxes',
      subsection: 'gas-semi-concealed'
    }
  ];
import React from 'react';
import { Link } from 'react-router-dom';
import './storysummary.css';

interface IStorySummaryProps {
  name: string;
  path: string;
  imageUrl: string;
  snippet: string;
}

export default function StorySummary({ name, path, imageUrl, snippet }: IStorySummaryProps) {
  return (
    <Link to={path} className="story-summary-link">
      <div className="story-summary">
        <img src={imageUrl} alt={name} />
        <div className="story-content">
          <h2>{name}</h2>
          <p>{snippet}</p>
        </div>
      </div>
    </Link>
  );
};


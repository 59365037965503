import '../page.css'

export default function NationalGridSemiCon() {
    const mainImageURL = '/images/boxes/National-Grid.jpg';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="National Grid Semi-Concealed Meter Box" className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>National Grid Semi-Concealed Meter Box</h1>
            <p>
            The National Grid Semi-concealed meter box is a ground box for a gas meter. It suffers from many of the issues that ground boxes suffer from. If water gets inside from the rain it might not drain, and water can come up from the ground.
            </p>
            <p>
            These boxes have a huge well in the base of the box. The reason for this is due to the size of the gas meters that the National Grid used to install.
            </p>
            <p>
            The problem with this large well is that it is larger than pretty much every domestic gas meter out there. So whilst a Sensus U6 will fit nicely in a Mitras Connect meter box, it will rattle around in a National Grid box. When I say rattle, that is sometimes literally.
            </p>
            <p>
            Other gas meters tend to be smaller than the Sensus U6, so fit even worse. Smaller meters such as Landis & Gyr meters, or Siemens, either fit very badly, or require some very nifty bracketing work from an engineer to get them in a position that both fits and allows you to read the meter.
            </p>
            <p>
            One issue with that fancy bracket work is that it can result in the meter facing upwards. Whilst this sounds great, it will make the meter easier to read, it probably goes against what the meter manufacturer says, which is that the meter should be situated standing up, not laid down. Additionally, if the meter is facing up, if your lid was to break, water would then be able to get into any cards slots on the meter or seep into the screen.
            </p>
        </section>
        </div>
    );
};


import React from 'react';
import './masthead.css'; 
import { NavLink } from 'react-router-dom';
import GetPath from '../../functions/getPath';


export default function Masthead() {
  const section = GetPath('section');

  switch (section) {
    case 'gas':
      return (
        <MastheadComponent 
          title="Gas"
          backgroundColor="#149edc"
          textColor="white"
          navLinks={[
            { path: "/gas/prepayment", label: "Prepayment" },
            { path: "/gas/smartmeter", label: "Smart Gas Meter" },
            { path: "/gas/dumbgasmeter", label: "Dumb Gas Meter" }
          ]}
        />);
    case 'meter-boxes':
      return (
        <MastheadComponent 
          title="Meter Boxes"
          backgroundColor="brown"
          textColor="white"
          navLinks={[
            { path: "/meter-boxes/gas-semi-concealed", label: "Gas Semi Concealed" },
            { path: "/meter-boxes/gas-cavity-box", label: "Gas Cavity Box" },
            { path: "/meter-boxes/gas-multi-box", label: "Gas Multi Box" },
            { path: "/meter-boxes/gas-surface-box", label: "Gas Surface Box" }
          ]}
        />);
    case 'about':
      return (
        <MastheadComponent 
          title="About"
          backgroundColor="#003399"
          textColor="white"
          navLinks={[]}
        />);
    default:
      return (
        <MastheadComponent 
          title="Welcome to I Am The Meter Man"
          backgroundColor="white"
          textColor="black"
          navLinks={[]}
      />);
  }
}

interface MastheadProps {
  title: string;
  backgroundColor: string;
  textColor: string;
  navLinks: { path: string; label: string; }[];
}

function MastheadComponent({ title, backgroundColor, textColor, navLinks }: MastheadProps) {
  return (
    <header className="site-masthead" style={{ background: backgroundColor }}>
      <div className="masthead-title" style={{ color: textColor }}>
        <h1>{title}</h1>
      </div>
      <nav className="masthead-nav">
        <ul>
          {navLinks.map(link => (
            <li key={link.label}>
              <NavLink 
                to={link.path} 
                className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
              >
                {link.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
}

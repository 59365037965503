import '../../page.css'

export default function SiemensE6() {
    const mainImageURL = '/images/meters/British-Gas-Meter.jpg';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="Siemens E6 Gas Meter" className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>Siemens E6 Gas Meter</h1>
            <p>
            The Siemens E6 Gas Meter first started to be used in the UK around 1993, it is a dumb meter, but with a more modern digital display readout.  The meter is around the same size as a Landis & Gyr meter.  
            </p>
            <p>This meter will sit in a wall cavity box without issue, and in a semi-concealed box.  Reading the meter is as simple as reading the digital readout.
            </p>
            <p>
            This meter was very popular with the National Grid, which installed around 2 million of them.
            </p>
            <p>
            There is the option to add a module to the meter to allow for card payments.
            </p>
            <p>
            One drawback of the Siemens E6 Gas Meter, and indeed any digital or smart meter, is that it is powered by a battery that will need to be changed.  The battery life of the E6 is around ten years., when the battery starts to go the digits on the digital display will start to fade until it is blank.  Once it is blank you will need to advise your supplier so that they can replace the battery or change the meter.  Although the readout is blank, it is still recording your gas usage, so don’t delay contacting your supplier thinking you are getting free gas.  They might estimate your readings which may cost you more.
            </p>
            <p>
            One thing to be noted about the battery life of this meter is that you probably won’t get ten years.  It is quite common for a gas engineer when removing an E6 meter to remove the battery if it is still working, and then use that old battery when sent on a job to replace a battery, thus you get lower battery life length.
            </p>
            <p>
            Over the years there have been some <a href="https://www.theguardian.com/money/2014/jun/16/faulty-british-gas-meter-bill-overpayment" target="_blank" rel="noopener noreferrer">issues</a> with this type of meter recording gas usage incorrectly.
            </p>
        </section>

        </div>
    );
};


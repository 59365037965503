import '../../page.css'

export default function LandisAndGyr370GasMeter() {
    const mainImageURL = '/images/meters/LandisAndGyr370.png';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="Landis & Gyr 370 Series Gas Meter" className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>Landis & Gyr 370 Series Gas Meter</h1>
            <p>
            The G370 series is a modular solution that satisfies the UK Smart metering requirements. A G370 module can be quickly retrofitted to existing L+G meters to upgrade economically with Smart capabilities. Landis & Gyr G370 provides for Credit mode, Prepayment mode (although rarely used by suppliers), AMI and Over the Air programmability with the proven E6V solid-state metrology.  The Landis & Gyr 370 is a first-generation smart meter and very popular with British Gas.  However, the updated version of this meter, the G470, is now being installed in new build houses.
            </p>
            <p>
            Perfect for fitting in a cavity wall box, this meter can also be fitted in a semi-concealed box.  The Landis & Gyr 130 and 210 do have some issues when used in a semi-concealed gas meter box, but that should not be the case with the 370.  As the 370 is a smart meter, with an in-home device to display your meter readings, none of the issues with none smart meters being fitted into semi-concealed gas meter boxes should be an issue.
            </p>
            <p>
            In theory, when you change supplier, your smart meter should still operate as normal.  In practice, this doesn’t happen, as different suppliers tend to prefer different types of smart meter.  So if you change suppliers, be aware that your smart meter may no longer be smart and you will have to supply meter readings, or have a new meter installed.  
            </p>
            <h2>Landis & Gyr 370 Series Gas Meter Features</h2>
            <p>
                <ul>
                    <li>Ultrasonic technology based on ‘time of flight’</li>
                    <li>UK SMETS compliant functionality</li>
                    <li>Integrated valve</li>
                    <li>Tamper detection</li>
                    <li>Credit / Prepayment – remote mode switching</li>
                    <li>Remote Disconnect / Reconnect</li>
                    <li>Over the Air programmability</li>
                    <li>Over the Air firmware upgrade</li>
                    <li>ZigBee SE compliant</li>
                    <li>Network Operator data logging</li>
                    <li>Greater reliability due to static technology</li>
                    <li>Position neutral installation</li>
                    <li>Small product size and weight</li>
                </ul>
            </p>
        </section>
    
        <section className="meter-reading-section">
            <h2>Reading The Landis & Gyr 370 Series Gas Meter</h2>
            <p>If you have an in-home display, then you will either not be required to supply a meter reading, or you can take a reading from the display.  However, if you do require to get a meter reading from the meter itself then it is very simple..</p>
            <p>
            On the front of the gas meter, there is a digital LCD screen.  Your gas meter is powered by a battery, so in order to save the battery life, the screen will automatically turn itself off.  You can turn on the screen by simply pressing the red button (just press and release the button).
            </p>
            <p>
            When you have done this the screen will light up and say “please wait”, it may take up to a minute, but the screen will eventually change to “credit on”.  Once you see the screen say “credit on” press the red button again and the screen will change to say “meter index” followed by some numbers such as “00325.345”, this is your meter reading, you only need the first five digits, in this case, “00325”.  
            </p>
            <p>
            That is your meter reading done, just leave the meter as it is, and the display will switch itself off in time.
            </p>
        </section>
        </div>
    );
};


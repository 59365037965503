import '../page.css'

export default function MultiBox() {
    const mainImageURL = '/images/boxes/Multibox.jpg';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="Gas Meter Multibox" className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>Gas Meter Multibox</h1>
            <p>
            The multibox is a ground box, so it sits on the ground. Unlike a gas meter cavity box or a surface box which are attached to the wall, and raised off the floor. The multibox, is very much like a unibox.
            </p>
            <p>
            The multibox has plenty of space so can fit any domestic gas meter without any issues. However, I have seen examples where an engineer has configured the box, meter and regulator in such a way that it has then been impossible to close the lid. If you do have that situation, call your gas supplier and they can send someone out to rectify it.
            </p>
            <p>
            The lid or door on a multibox is sturdy and shouldn’t break unless you are sitting on the box for some unknown reason. It will protect the meter from any rain getting into the box.
            </p>
            <p>
            The gas supply to the box from the mains will come into the box from the ground and exit the box usually on the upper right-hand side.
            </p>
            <p>
            As with any ground box, you could have issues with water coming up from the ground into the box if the seal around the supply coming in, isn’t perfect. However, the box does have a large well so can hold quite a bit of water which would then soon drain away once the ground has dried out.
            </p>
        </section>
        </div>
    );
};


import '../../page.css'

export default function LandisAndGyr210GasMeter() {
    const mainImageURL = '/images/meters/LandisAndGyr210.png';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="Landis & Gyr Libra 210 Advanced PrePayment" className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>Landis & Gyr Libra 210 Advanced PrePayment</h1>
            <p>
            The Libra 210 module provides the UK industry-standard method of gas pre-payment functionality through a secure Smart Card system. When fitted to a meter the module will account for gas consumption on a block tariff supporting up to 6 blocks, recover debt, provide controlled emergency credit and provides tariff updates through the POS system. The Libra 210 is fully compatible with the established UK Quantum system infrastructure. The Libra 200 series is present in more than two million homes.
            </p>
            <p>
            Much the same in looks as the Landis & Gyr G130, this meter is ideally suited in a cavity wall box.  It can be, and often is fitted in a semi-concealed gas meter box.  Much like the G130, the meter can be fitted at an angle within a semi-concealed meter box, however the card slot on this meter does allow water to get inside and stop the meter from working.
            </p>
            <h2>Features</h2>
            <p>
                <ul>
                    <li>Meter accommodates add-on modules</li>
                    <li>Based upon the proven E6V meter</li>
                    <li>Provides Quantum system prepayment functionality</li>
                    <li>Accountancy for gas consumption on a block tariff</li>
                    <li>Debt recovery</li>
                    <li>Provide controlled emergency credit</li>
                </ul>
            </p>
        </section>
    
        <section className="meter-reading-section">
            <h2>Householders</h2>
            <h3>Topping Up</h3>
            <p>Your method for topping up your gas meter may vary depending on your supplier if you have a smart electric meter and if you have an in-home monitor, the strength of the mobile phone signal at your home.
            </p>
            <p>
            The most common way to top up your meter is by adding credit to a payment card at any PayPoint location. 
            You can find your nearest PayPoint location on their website 
            <a href="http://www.paypoint.co.uk/locator" target="_blank" rel="noopener noreferrer">here</a>.
            </p>
            <p>
            Once the payment has been made your meter might automatically add the credit, again this depends on your suppliers set up, but please allow 30 minutes for this to happen, it may take longer if you have a weak mobile phone signal in your area. You can also top up by inserting the card in the meter, if the meter screen is blank, wake the meter by pressing any button. Then insert your card, and follow the on-screen instructions. You can also top up manually. Goto you meter, if your screen is blank wake the display by pressing any button. Press the red button A until the following is displayed: Credit Entry? A=Next B=Accept. Press the black button B to continue. The meter will show: New credit *******************. Press button A to scroll through the numbers on your 20 digit purchase code on the bottom of your PayPoint receipt. Press button B when you are ready to move on to the next number. Repeat until you have fully entered the code.
            </p>
        </section>
        </div>
    );
};


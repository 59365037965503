import '../page.css'

export default function MitrasConnect() {
    const mainImageURL = '/images/boxes/Mitras-Connect.jpg';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="Mitras Connect Semi-Concealed Meter Box" className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>Mitras Connect Semi-Concealed Meter Box</h1>
            <p>
            The Mitras Connect Semi-Concealed Meter Box is probably the semi-concealed gas box that most gas engineers would prefer to use. I mean, they would prefer not to use a semi-concealed box at all, but if they had to choose one it would be this.
            </p>
            <p>
            The connect box is a spacious box, with a nice well to allow meters such as the Sensus U6, QK 2000, G4 Diaphragm to all fit in nicely.
            </p>
            <p>
            One issue that often occurs with a semi-concealed meter box is that of fitting in smaller smart meters such as the Landis & Gyr G370. This is due to the shape of these meters, they are not designed to sit in wells.
            </p>
            <p>
            However, the connect meter box has enough room to allow an engineer to fit brackets on to which the smaller meter can be mounted.
            </p>
            <p>
            It is a big frustration for many that they try to get a smart meter that their supplier has been badgering them about for months, only to have an engineer come out and say that they cannot fit the meter in that type of semi-concealed box. You shouldn’t have that issue with this box.
            </p>
            <p>
            As with all ground boxes, you may have problems with water. Rainwater could get in the box if the lid is broken, the connect box lid does have a habit of warping a little so that it doesn’t sit straight. You can also have an issue with groundwater coming up into the box where the mains gas supply comes in.
            </p>
        </section>
        </div>
    );
};


import '../../page.css'

export default function ElsterThemisAlpha() {
    const mainImageURL = '/images/meters/SciFlo-Smart-Gas-Meter.jpg';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="SciFlo Smart Gas Meter" className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>SciFlo Smart Gas Meter</h1>
            <p>
            The SciFlo Smart Gas Meter from Flonidan is a great little smart meter.  Housed in steel this meter is designed for residential use, is lightweight, with a great battery lifespan for the digital display screen.
            </p>
            <p>
            A smart gas meter is not connected to the mains electricity, so the screen on the meter is powered by a battery.  When the battery runs out, then an engineer will be required to come out and replace it, or replace the meter.  The SciFlo battery lasts 15 years.  This is longer than the low battery life of a less functional meter such as the Siemens.
            </p>
            <p>
            If we forget about the issue of the lifespan of the battery.  If I was to guesstimate the lifespan of the meter, I would say between 15 and 20 years.  So in the case of a SciFlo Smart Gas Meter, when the battery runs out, it is probably time to change the meter anyway.
            </p>
        </section>
    
        <section className="meter-reading-section">
            <h2>How to read a SciFlo Gas Meter</h2>
            <p>Taking a reading from a SciFlo gas meter is very simple.</p>
            <img src={'/images/meters/Reading-a-Flonidan-Gas-Meter-300x152.jpg'} alt="Reading a smart meter" className="main-image" />
            <p>
            The meter has a digital display screen which will probably be blank.  Under the screen, you will see three buttons marked ‘A’, ‘B’ and ‘C’.
            </p>
            <p>
                To take a meter reading from a SciFlo gas meter, simply press the button marked ‘A’.
            </p>
            <p>
The screen should light up, and display a meter reading.  The reading will have the letter ‘v’ at the start, meaning ‘volume of gas used’, with an ‘m3’ at the end, meaning cubic meters.  To get your meter reading, write down the numbers before the decimal point, so in this example, the meter reading is 06532.</p>
        </section>
        </div>
    );
};


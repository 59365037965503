import { useLocation } from "react-router-dom";

/**
 * Retrieves either the full path, section, or subsection from the current URL pathname.
 *
 * @param {'fullpath' | 'section' | 'subsection'} pathSegment - The specific part of the path to return.
 * 'fullpath' returns the entire pathname.
 * 'section' returns the first segment of the pathname following the initial '/'.
 * 'subsection' returns the second segment of the pathname following the initial '/'.
 * If there are no sections or subsections, 'home' is returned by default.
 *
 * @returns {string} Depending on the `pathSegment` argument, returns either the full pathname, 
 * the first section, the second section, or 'home' if no sections are found in the pathname.
 *
 * @example
 * // If the URL is "http://example.com/gas/prepayment" and you call getPath('section'),
 * // it will return "gas".
 *
 * @example
 * // If the URL is "http://example.com/gas/prepayment" and you call getPath('subsection'),
 * // it will return "prepayment".
 *
 * @example
 * // If the URL is "http://example.com/gas/prepayment" and you call getPath('fullpath'),
 * // it will return "/gas/prepayment".
 */
export default function GetPath(pathSegment: 'fullpath' | 'section' | 'subsection'): string {
    const location = useLocation();
    const path = location.pathname;

    if(pathSegment === 'fullpath') { return path; }
  
    const pathSegments = path.split('/').filter(Boolean);

    if(pathSegment === 'subsection' && pathSegments.length > 2) { return '';}
    return pathSegments.length > 0 ? pathSegments[pathSegment === 'section' ? 0 : 1] : 'home';
}

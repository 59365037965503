import '../../page.css'

export default function Bobi() {
    const mainImageURL = '/images/meters/BOBi.jpg';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="BOBi G4 Gas Meter" className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>BOBi G4 Gas Meter</h1>
            <p>
            The BOBi G4 Diaphragm Gas Meter is a dumb gas meter made by Meters UK. It is a fairly industry-standard gas meter made from aluminium. I would estimate the lifespan of this meter to be up to 20 years.
            </p>
            <p>
            The BOBi fits well in a cavity box, and will fit in most semi-concealed gas meter boxes, but not as neatly as a Sensus U6 would do.
            </p>
        </section>
    
        <section className="meter-reading-section">
            <h2>Reading a BOBi G4</h2>
            <img src={'/images/meters/MeterRead.jpg'} alt="Reading a smart meter" />
            <p>
            Reading a BOBi G4 gas meter is very easy. Simply make a note of the digits on the meter, reading from left to right. Remembering to include any leading zeros. Any digits surrounded in red should be ignored, we are only interested in the ones surrounded in black. So in the case, the meter reading is 00693.
            </p>
        </section>

        <section className='content-section'>
            <h1>FAQ</h1>
            <p>
                <strong>Is this a smart meter?</strong>
                <br />
                <p>
                No, this is a dumb meter.  We do often get asked this, and one reason is perhaps due to the cable you can see in the photograph.

                There are some addons that can be used to auto-read dump meters. They attached to the front of the meter and in effect read the digits on the meter. These tend to only be used as a way to automatically get a meter reading for the supplier. If reading the meter is becoming an issue then ask your supplier to replace the meter.
                </p>
            </p>
        </section>
        </div>
    );
};


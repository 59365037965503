import '../page.css'

export default function SplashMeterBox() {
    const mainImageURL = '/images/mixed/CheckingMeterMan.jpg';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="Meter Boxes" className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>Meter Boxes</h1>
            <p>
            Unlike in this image, meters are not outside open to the elements. They are housed in meter boxes. These boxes come in a variety of shapes and sizes, and are made from a variety of materials.
            </p>
            <p>
            Depending on the age of the property, it is possible that the meter is inside the property, in a cupboard or under the stairs. Newly built properties will have the meter outside, in a meter box.
            </p>
            <p>
            Appartments and flats might have a meter room or meter cupboard, where all the meters for the building are housed.
            </p>
            <p>
            If you are looking for information on a specific meter box, please use the navigation above to find you meter box type.
            </p>
        </section>
        </div>
    );
};


export default function About() {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '16px' }}>
      <div style={{ flex: '1', paddingRight: '16px' }}>
        <p>Hi there, I'm the meter man, I worked in the utilities industry for a number of years, but have since moved on to pastures new.</p>
        <p>My aim with this site is to provide a resource for people who are looking for information on gas meters and meter boxes.</p>
        <p>Whilst an in-home device is great for reading your meter, if it fails then working out how to get a meter reading from some meters can be a bit tricky.</p>
        <p>Hopefully I can help you with that.</p>
        <p>Thanks for visiting.</p>
        <p>If I have helped in any way, then feel free to say thank you by buying me a coffee.</p>
      </div>
      <div style={{ flex: '0 0 300px', textAlign: 'right' }}>
        <script 
          data-name="BMC-Widget" 
          data-cfasync="false" 
          src="https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js" 
          data-id="countdisoQ" 
          data-description="Support me on Buy me a coffee!" 
          data-message="If this site has helped you in any way then I'm very pleased. If you want to show your appreciation, feel free to buy me a coffee." 
          data-color="#5F7FFF" 
          data-position="Right" 
          data-x_margin="18" 
          data-y_margin="18">
        </script>
      </div>
    </div>
  );
}

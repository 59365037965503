import React from 'react';
import './footer.css'; 
import '../../App.css';

export default function Footer() {
  const currentYear = new Date().getFullYear(); 

  return (
    <footer className="site-footer">
        <p>At I am the Meter Man, we aim to bring you simple, easy-to-understand information about your utility meters.</p>
        <a href="https://www.buymeacoffee.com/countdisoQ"><img src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=countdisoQ&button_colour=FFDD00&font_colour=000000&font_family=Cookie&outline_colour=000000&coffee_colour=ffffff" /></a>
      <div className="footer-content">
        <p>© {currentYear} I Am The Meter Man. All rights reserved.</p>
        <p>Providing trusted meter advice since 2020.</p>
      </div>
    </footer>
  );
};



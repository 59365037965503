import '../../page.css'

export default function UnifloSmartGasMeter() {
    const mainImageURL = '/images/meters/Flonidan-Gas-Meter.jpg';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="Uniflo Smart Gas Meter" className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>The Flonidan Uniflo Smart Gas Meter</h1>
            <p>
            The Flonidan Uniflo Smart Gas Meter is a great meter which comes with lots of great functionality, even though it might not look as advanced as say a Landis & Gyr 470 or an Elster smart meter.  It can be used as a pre-payment meter.

The Uniflo is reliable and sturdy.  It is a little thin, somewhat like the G4, which means that whilst it will fit in a semi-concealed meter box, it doesn’t fit as neatly as a Sensus U6 and can rattle about in the box.
            </p>
            <p>
            A smart gas meter is powered by a battery, they are not connected to the mains electricity.  This does mean that over time the battery will run out.  The Siemens digital meter often had issues with the battery life not lasting as long as it should.  When the battery runs out, you can no longer get a reading from the meter, and an engineer will need to come out and change the battery (or just change the meter).  The Uniflo Smart Gas Meter does have something of an advantage on other smart meters, in that the battery life is expected to be 15 years, this is more than the usual 10 years.
            </p>
        </section>
    
        <section className="meter-reading-section">
            <h2>Getting A Meter Reading from a Uniflow Smart Gas Meter</h2>
            <p>To get a meter reading from the Flonidan Uniflo gas meter is quite simple...</p>
            <img src={'/images/meters/Reading-a-Flonidan-Gas-Meter-300x152.jpg'} alt="Reading a smart meter" className="main-image" />
            <p>
            Simply press the button on the left, the one that is marked ‘A’.  This should make the screen light up and display your meter reading.  Your reading will be prefixed with a ‘V’ for volume, followed by the reading.  In this example the meter reading is 06532.
            </p>
        </section>
        </div>
    );
};


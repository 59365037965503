import React, { useState } from 'react';
import './navbar.css'; 
import logo from '../../images/logo.png';
import HomePage from '@mui/icons-material/HomeOutlined';
import Gas from '@mui/icons-material/LocalFireDepartmentOutlined';
import MeterBox from '@mui/icons-material/Inventory2Outlined';
import AboutIcon from '@mui/icons-material/InfoOutlined';

interface NavBarItemProps {
  label: string;
  path: string;
}

const NavBarItem: React.FC<NavBarItemProps> = ({ label, path }) => {

  // create a switch statement to return the correct icon for the label
  const getIcon = (label: string) => {
    switch (label) {
      case "Home":
        return <HomePage sx={{ color: '#00ff00' }} />;
      case "Gas":
        return <Gas sx={{ color: '#149edc' }} />;
      case "Meter Boxes":
        return <MeterBox sx={{ color: '#964B00' }} />;
      case "About":
        return <AboutIcon sx={{ color: '#003399' }} />;
      default:
        return <HomePage sx={{ color: '#000000' }} />;
    }
  }

  return (
    <li className="nav-item">      
        <a href={path}>{getIcon(label)}{label}</a>
    </li>
  );
};

export default function NavBar() {
  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <div className="navbar-container">
      <div className="logo">
        <a href="/">
          <img src={logo} alt="Meter Man Logo" />
        </a>
      </div>
      <div className="site-title">
        <h1>I Am The Meter Man</h1>
      </div>
      <button className="nav-toggle" onClick={toggleNav}>☰</button> {/* Simple hamburger icon */}
      <div className={`divider ${isNavVisible ? 'show' : ''}`}></div>
      <nav className={`navbar ${isNavVisible ? 'show' : ''}`}>
        <ul className="nav-list">
          <NavBarItem label="Home" path="/" />
          <NavBarItem label="Gas" path="/gas" />
          <NavBarItem label="Meter Boxes" path="/meter-boxes" />
          <NavBarItem label="About" path="/about" />
        </ul>
      </nav>
    </div>
  );
}



import '../page.css'

export default function CavityBox() {
    const mainImageURL = '/images/boxes/GasCavity.jpg';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="Gas Meter Cavity Box" className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>Gas Meter Cavity Box</h1>
            <p>
            Having a gas meter within a cavity box is very common. A cavity box is simply a box that sits within a cavity in the wall of your home.
            </p>
            <p>
            Your electric meter will probably be in a cavity box. You should be able to spot the difference between a gas cavity box and an electric cavity box quite easily. One has a gas meter in it! No, I jest, the cavity box for the gas meter should have a door with ventilation slots.
            </p>
            <p>
            Sometimes you will see that a gas cavity box doesn’t have these slots. This might be because the person installing the box when the house was built got mixed up as to if he was putting in a gas or electric box. It could also be that the door on the gas box got damaged and replaced with an electric box door, rather than a gas one.
            </p>
            <p>
            If you do spot that you have the wrong door, you can ask your supplier to come out and change it, or buy a replacement door and fit it yourself.
            </p>
            <p>
            A gas meter cavity box is ideal for any type of gas meter, from a Sensus U6 to a Landis & Gyr G470.
            </p>
        </section>
        <section className="content-section">
            <h1>The Gas Supply</h1>
            <p>
            The gas supply going into the gas meter cavity box should come via a pipe going up into the meter box. This pipe will be a branch from the nearby mains gas supply and will be covered with a hardwearing pipe, something like a very thick drain pipe.
            </p>
            <p>
            The gas supply coming out of the gas cavity meter box should be visible. It should not go into your property by going out of the back of the meter box. If it does, inform your supplier so that they can arrange for an engineer to take a look.
            </p>
            <p>
            A gas cavity meter box is usually pretty durable, apart from if the lock goes, which then often leads to the door breaking having been blown open by the wind.
            </p>
        </section>
        </div>
    );
};


import '../page.css'

export default function Mitras() {
    const mainImageURL = '/images/boxes/Mitras.jpg';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="Mitras Semi-Concealed Meter Box" className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>Mitras Semi-Concealed Meter Box</h1>
            <p>
            The Mitras Semi-Concealed Meter Box is a ground gas meter box that is very strong. You are pretty much certain that if you get a call out to repair a damaged Mitras Semi-Concealed Meter Box then it was probably damaged by a car running over it, twice.
            </p>
            <p>
            These meter boxes are suitable for most types of older gas meters, but newer smaller smart meters such as the Landis & Gyr G470 can be a little tricky to fit in a manner that is safe and usable.
            </p>
            <p>
            As with all ground boxes, you do often have the issue that the meter will be hard to read if it has a forward-facing screen. Rain could get into the box if the lid is damaged, you know, if that car after running over the Mitras box twice then decided to jump on it a few times. You can also have an issue with water coming up from the ground into the box.
            </p>
        </section>
        </div>
    );
};


import '../../page.css'

export default function SensusU6() {
    const mainImageURL = '/images/meters/SensusU6.png';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="Sensus U6" className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>Sensus U6</h1>
            <p>
            U6 SENSUS (Cubix) U6 OFGEM Approved domestic gas meter is designed for measurement of gas supplied to residential, commercial and industrial premises where maximum consumption of gas is equivalent to 6m3/h of air of density of 1,2 kg/m3. </p>
            <p>
            The Sensus U6 is a very sturdy gas meter, hardwearing and highly reliable, this meter is far more suited to use in a semi-concealed meter box than say a Landis and Gyr smart meter.
            </p>
            <p>
            Ssssh, don't tell the other meters, but this might be my favourite meter.  It is a very reliable meter, and I have seen many of these meters still going strong after 30 years.  The U6 is a very robust meter, and is very easy to read.  The U6 is a very popular meter in the UK, and is used by many of the big six energy suppliers.
            </p>
            <h2>Features</h2>
            <p>
                <ul>
                    <li>The U6 Sensus gas meter is approved to BS746 British Standard / OFGEM Approved</li>
                    <li>Suitable for Natural Gas and LPG Installations</li>
                    <li>Fits standard household 152mm meter box brackets</li>
                    <li>Pulse transmitter can be added at any time (1 imp = 0,01 m3).</li>
                    <li>U6 1″ Domestic/residential/household</li>
                    <li>Diaphragm Gas Meter Qmax 6 m3/h</li>
                    <li>Fireproof to EN1359</li>
                    <li>BS746 Connections</li>
                </ul>
            </p>
        </section>

        <section className="meter-reading-section">
            <h2>Reading a Sensus U6 Gas Meter</h2>
            <img src={'/images/meters/MeterRead.jpg'} alt="Reading a dumb meter" />
            <p>
            Your U6 meter is very simple to read. You will see the meter dials, five dials surrounded in black, and three dials surrounded in red. Always read from left to right. The meter reading here is 00693. The numbers surrounded in red are ignored.
            </p>
            <p>
            A newer U6 meter may differ from an older meter in that it is recording cubic meters (m3) rather than cubic feet (ft3) which your old meter may have been measuring. This does not affect how much you are charged for your gas.
            </p>
        </section>
        </div>
    );
};


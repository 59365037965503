import '../page.css'

export default function SurfaceBox() {
    const mainImageURL = '/images/boxes/SurfaceBox.jpg';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="Surface Box" className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>Surface Box</h1>
            <p>
            Firstly, not really sure why these are called surface boxes. They don’t sit on a surface. Very much like a gas meter cavity box, this box will be attached to the side of your property.
            </p>
            <p>
            Unlike the gas meter cavity box it will not be built into a cavity in the wall, but instead, just be attached to the wall. This does mean that the gas meter surface box sticks out a lot more than its cavity counterpart.
            </p>
            <p>
            What is very different about the surface box, and is perhaps why it is called a surface box, is that it will be situated lower than a cavity box is. This does mean that it is not as easy to read the gas meter inside the box.
            </p>
            <p>
            You will often see these boxes on old terraced houses, that were built before gas was common. These types of houses wouldn’t have a cavity space for a gas meter cavity box and fitting a semi-concealed meter box would be difficult as the house will usually have concrete next to the wall.
            </p>
            <p>
            Most meter types will fit into a gas meter surface box, Sensus U6, Elster without any issues as these boxes do have lots of space.
            </p>
            <p>
            One issue with the surface box is that the doors do often break, and getting a replacement door is not as easy as it is with a cavity box, as very few places sell the doors on their own.
            </p>
        </section>
        </div>
    );
};


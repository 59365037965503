import '../../page.css'

export default function G4Diaphragm() {
    const mainImageURL = '/images/meters/G4Dia.jpg';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="G4 Diaphragm Gas Meter" className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>G4 Diaphragm Gas Meter</h1>
            <p>
            The G4 Diaphragm Gas Meter is a very basic dumb gas meter.  In the same vein as the Sensus U6 meter this is a sturdy gas meter with at least a twenty-year life span. </p><p>Slightly slimmer than the U6 it will fit in a semi-concealed meter box, but not quite as snuggly, but is certainly a much better fit than say the Nuovo.  The readout window is forward-facing, so is a little more difficult to read in a semi-concealed gas meter box.
            </p>
        </section>

        <section className="meter-reading-section">
            <h2>Reading a G4 Diaphragm Gas Meter</h2>
            <p>The dial for the meter is the same as other common dumb meters, and very easy to read.</p>
            <img src={'/images/meters/MeterRead.jpg'} alt="Reading a dumb meter" />
            <p>
            Like other dumb meters, to get a meter reading you should record the numbers that are surrounded in black, and the red numbers (which are fractions) should be ignored.
            </p>
        </section>

        </div>
    );
};


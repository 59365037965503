import '../page.css'

export default function IGT() {
    const mainImageURL = '/images/boxes/IGT.jpg';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="IGT Semi-Concealed Meter Box" className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>IGT Semi-Concealed Meter Box</h1>
            <p>
            The IGT (Independent Gas Transporters) Semi-Concealed Meter Box was a very common box for newbuild houses through the 1990’s – 2000’s with the boom of independent gas transporters.
            </p>
            <p>
            The box, in general, is a strong box, with a solid lid and doesn’t have too many issues, apart from one. A major one.
            </p>
            <p>
            It isn’t great in terms of space, and you will struggle with fitting certain meter types. Don’t get me wrong, it is not the worst, that honour is held by the National Grid’s semi-concealed box.
            </p>
            <p>
            As with all ground boxes, water coming up from the ground into the box, can be an issue.
            </p>
        </section>
        </div>
    );
};


import '../page.css'

export default function SplashMeterGas() {
    const mainImageURL = '/images/mixed/CheckingMeterMan2.jpg';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="Gas Meters " className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>Gas Meters</h1>
            <p>
            In this section we offer information on a variety of gas meters, from prepayment meters to smart meters and traditional dumb meters.
            </p>
            <p>
            Using the navigation above, you can find information on the meter you have, or the meter you are looking to have installed.
            </p>
        </section>
        </div>
    );
};


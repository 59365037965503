import '../../page.css'
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';

export default function LandisAndGyr350() {
    const mainImageURL = '/images/meters/LandisGyr350.jpg';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="Landis & Gyr G350 Gas Meter" className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>Landis & Gyr G350 Gas Meter</h1>
            <p>
            A smart meter that packs a punch.  Look, when it comes to this meter this is a lot of technical stuff I could say about the different communication methods it has but, all you need to know is it can talk to its compatible electric meter, which in turn updates your in-home display.
            </p>
            <p>
            Ideal in a wall cavity box, but can be used in a semi-concealed gas meter box, as you shouldn’t need to take a direct reading.
            </p>
            <h2>Features</h2>
            <ul>
                <li>Remote valve operation</li>
                <li>Remote meter reading</li>
                <li>Tamper alerts</li>
                <li>Temperature compensation</li>
                <li>Pressure adjustment factor</li>
                <li>Service functions</li>
                <li>Test Mode</li>
            </ul>
            <p>
            You can view documentation for this meter here.
                <a href="/pdf/Libra-350-Brochure_English.pdf" target="_blank" rel="noopener noreferrer">
                    <PictureAsPdfOutlinedIcon color={'error'} />
                </a>
            </p>
        </section>
        </div>
    );
};


import '../../page.css'

export default function LandisAndGyr470() {
    const mainImageURL = '/images/meters/LandisAndGyr470.jpg';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="Landis & Gyr G470 Gas Meter" className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>Landis & Gyr G470 Gas Meter</h1>
            <p>
            The Landis & Gyr G470 gas meter is a second-generation smart meter, essentially an upgrade on the G370.  Stylish and simple to use this meter promises to be around for some time.
            </p>
            <p>
            Used by British Gas the G470 connects with the Landis & Gyr E470 electric meter, which in turn updates an in-home display with your usage.  However, as with many gas and electric smart meters, although it should be simple to swap suppliers and retain functionality, most suppliers don’t transfer that functionality and your smart meter may become a dumb meter upon switching.
            </p>
            <p>
            Much like its predecessor, this gas meter can also operate as a pre-payment meter.
            </p>
            <p>
            As the Landis & Gyr G470 gas meter is quite new to the market we are as yet unsure as to how long the battery life will be.  It would be easy to say that it is the same as the G370, but this meter does have more functionality, so could use much more battery power.
            </p>
            <p>
            These meters have proven to be reliable and are a great way to monitor your usage.
            </p>
        </section>
        </div>
    );
};


import '../page.css'

export default function Unibox() {
    const mainImageURL = '/images/boxes/Unibox.jpg';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="Gas Meter Unibox" className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>Gas Meter Unnibox</h1>
            <p>
            The gas meter unibox is a gas meter box that sits on the floor. So rather than a gas meter cavity box, or a surface box both of which are on the wall lifted off the floor, the unibox is sat on the floor.
            </p>
            <p>
            A unibox is quite a spacious meter box. The gas supply will come up through the ground and into the box at the left-hand side. The supply from the box to your property will usually come out of the box on the upper right-hand side.
            </p>
            <p>
            Uniboxes are very sturdy boxes with plenty of space even for larger domestic meters such as the Sensus U6, whilst still suitable with the use of a bracket for the likes of the Landis & Gyr G370.
            </p>
            <p>
            As with any ground meter box, there is a chance of water getting into the box. Unless the lid is broken, rainwater shouldn’t find its way into the box, the main worry is water coming up from the ground if the seal isn’t complete on the gas supply coming into the box.
            </p>
            <p>
            However, as this box does have a huge well, then this is very rarely an issue. Unless you have a tropical downpour for days, any water that did get into the box would drain away as the ground dried out.
            </p>
        </section>
        </div>
    );
};


import '../../page.css'

export default function QK2000() {
    const mainImageURL = '/images/meters/qk2000.jpg';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="QK 2000 Dumb Gas Meter" className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>QK 2000 Dumb Gas Meter</h1>
            <p>
            The QK 2000 Dumb Gas Meter is aluminium gas meter, which is lightweight and produces very little noise (some gas meters can start to hum or create a rattling noise).  The QK 2000 has a lifespan of around 12 years, which is a shorter than the 20-year lifespan you get with something like a Sensus U6 meter.</p>
            <p>
            This meter is actually made to German standards, so shouldn’t be found in the UK, however, we have included it after stumbling across a couple.
            </p>
        </section>
    
        <section className="meter-reading-section">
            <h2>How to read a QK2000 Gas Meter</h2>
            <p>Taking a meter reading from a QK 2000 dumb gas meter is very simple, and they are very accurate.</p>
            <img src={'/images/meters/MeterRead.jpg'} alt="Reading a smart meter" />
            <p>
            The numbers on the dials should be read from left to right, and only the numbers in black should be read, the numbers in red should be ignored.  Any zero’s at the front should be included in your meter reading.  So in the case, the meter reading is 00693.
            </p>
        </section>
        </div>
    );
};


import React from 'react';
import {snippets} from './PageSnippets';
import StorySummary from '../components/storysummary/storysummary';
import GetPath from '../functions/getPath';
import './Stories.css';


export default function Stories() {
    const section = GetPath('section');
    const subsection = GetPath('subsection');
    // write the filterSnippets function
    const filterSnippets = (section: string, subsection: string) => {
        return snippets.filter((s) => s.section === section && s.subsection === subsection);
    };
    // filter the snippets based on the section and subsection
    const filteredSnippets = filterSnippets(section, subsection);

    return (
        <div className='stories-container'>
            {filteredSnippets.map((s) => (
                <StorySummary key={s.name} {...s} />
            ))}
        </div>
    );
};



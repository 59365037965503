import React from 'react';
import './HomePage.css'; // Make sure to import your CSS stylesheet

// You can import the images if you're using modules
import meterImage from '../images/Meter at 0000.jpg'; // Replace with the actual path to your image

export default function HomePage() {
  return (
    <div className="homePage">
      <main>
        <section className="introSection">
          <img src={meterImage} alt="Meter" className="responsiveImage" />
          <div className="textContainer">
            <h1>Our Mission</h1>
            <p>Here to help you with issues relating to your energy and utility meters. From understanding your smart meter to reducing your energy bills, we've got you covered.</p>
            <p>Want to know how to get a meter reading from your Smart Gas Meter?  Then this is the place to come to!</p>
            <p>We want to help you get the best value for money from your gas and electricity supplier.</p>
            <p>Our knowledge comes from a team of experienced utility contractors, suppliers and manufacturers.</p>
            <h2>Website Update</h2>
            <p>If you have visited us before you might have noticed that the site has changed!</p>
            <p>We are working on quite a few updates so please bare with us if you discover some oddities during this time.</p>
          </div>
        </section>
      </main>
    </div>
  );
};

import '../../page.css'

export default function LandisAndGyr110() {
    const mainImageURL = '/images/meters/Libra110.jpg';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="Landis+Gyr Libra 110 Gas Meter" className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>Landis+Gyr Libra 110 Gas Meter</h1>
            <p>
            This is a nifty little pre-payment gas meter, much like its cousin the 210, but somehow more stylish and certainly seems to breakdown much less! 
            </p>
            <p>
            The Landis+Gyr Libra 110 Gas Meter accepts credit in cubic meters of gas purchased, so that there is no updating of tariffs directly on the meter, as there is with some other pre-payment meters.  Instead, the price of the gas is altered at the point of sale.
            </p>
            <p>
            Ideal in a wall cavity box, but not great in a semi-concealed meter box as the card slot can let in water.
            </p>
            <h2>Features</h2>
            <ul>
                <li>Modular construction – compatible with the E6V Adaptive base meter</li>
                <li>Low-cost memory card used for top-ups</li>
                <li>Low-cost POS tariff management</li>
            </ul>
        </section>
        </div>
    );
};


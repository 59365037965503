import '../../page.css'

export default function NuovoPignone() {
    const mainImageURL = '/images/meters/NuovoPignone.jpg';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="Nuovo Pignone" className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>Nuovo Pignone</h1>
            <p>
            The Nuovo Pignone is a hardy dumb gas meter.  Smaller than the more popular Sensus dumb meter, the Nuovo Pignone is almost just as robust a reliable. </p>
            <p>
            One issue is that the Nuovo does get dwarfed in some meter box installations and sits a little uneasy.  In a wall cavity meter box the Nuovo Pignone takes up very little space and does look rather odd.  In a semi-concealed meter box, the Nuovo Pignone really isn’t suited.  A sloppy engineer will leave it sat in the well of the gas meter box, making it almost impossible to read.  A good engineer will do their best to fit it on a bracket within the box, but they are really not suitable for this type of gas meter box.
            </p>
            <p>
            There are a couple of slightly different versions of the Nuovo, one of the main differences is that some don’t have a test nipple, instead the test nipple is on the outlet flexible pipework.
            </p>
            <p>
            The Nuovo is rarely used as a new install in the UK now.
            </p>
        </section>

        <section className="meter-reading-section">
            <h2>Reading a Nuovo Pignone Gas Meter</h2>
            <p>Reading a Nuovo Pignone is just the same as reading other dumb gas meters such as the Sensus meter.</p>
            <img src={'/images/meters/MeterRead.jpg'} alt="Reading a dumb meter" />
            <p>
            The display will show five digits surrounded in black, and three digits surrounded in red.  The reading should be taken left to right, and the digits in read should be ignored.  So this reading would be 00693.
            </p>
        </section>

        <section className="content-section">
            <h2>FAQ</h2>
            <h3>I'm looking at trying to automate reading one of these meters by following the steps outlined in post on Reddit, do you know if this meter has a magnet on the last digit as described in that post?</h3>
            <p>
            Ooooh, interesting.  Very good question, and I don't know the answer.  I have seen many Nuovo Pignone with automated meter readers, however, as mentioned above, I have seen quite a few different versions of the Nuovo Pignone, so couldn't say if they all have a magnet on the last digit. 
            </p>
        </section>

        </div>
    );
};


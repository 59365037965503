import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import HomePage from './pages/homepage';
import NavBar from './components/navbar/navbar';
import Footer from './components/footer/footer';
import Masthead from './components/masthead/masthead';
import Stories from './pages/Stories';
import UnifloSmartGasMeter from './pages/gas/prepayment/uniflo-smart-gas-meter';
import LandisAndGyr110 from './pages/gas/prepayment/libra-110';
import LandisAndGyr350 from './pages/gas/prepayment/landis-and-gyr-350';
import LandisAndGyr470 from './pages/gas/prepayment/landis-and-gyr-470';
import LandisAndGyr370 from './pages/gas/prepayment/landis-and-gyr-370';
import LandisAndGyr210 from './pages/gas/prepayment/landis-and-gyr-210';
import './App.css';             
import ScifloSmartGasMeter from './pages/gas/smartmeters/sciflow-smart-gas-meter';
import ElsterThemisAlpha from './pages/gas/smartmeters/sciflow-smart-gas-meter';
import BlankPage from './pages/BlankPage';
import Bobi from './pages/gas/dumbmeters/bobi';
import QK2000 from './pages/gas/dumbmeters/qk2000';
import SiemensE6 from './pages/gas/dumbmeters/siemens';
import G4Diaphragm from './pages/gas/dumbmeters/g4diaphragm';
import LandisAndGyr130 from './pages/gas/dumbmeters/landis-and-gyr-g130';
import NuovoPignone from './pages/gas/dumbmeters/nuovo-pignone';
import SensusU6 from './pages/gas/dumbmeters/sensusu6';
import SurfaceBox from './pages/boxes/surfacebox';
import MultiBox from './pages/boxes/Multibox';
import Unibox from './pages/boxes/Unibox';
import CavityBox from './pages/boxes/Cavity';
import MitrasConnect from './pages/boxes/MitrasConnect';
import Mitras from './pages/boxes/Mitras';
import NationalGridSemiCon from './pages/boxes/NationalGrid';
import IGT from './pages/boxes/IGT';
import SplashMeterBox from './pages/boxes/Splash';
import SplashMeterGas from './pages/gas/Splash';
import Privacy from './pages/Privacy';
import About from './pages/About';


const App: React.FC = () => {
  return (
    <Router>
      <div className='site-content'>
        <NavBar />
        <Masthead />
        <Stories />
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/* Gas */}
            <Route path="/gas" element={<SplashMeterGas />} />
            {/* Prepayment */}
            <Route path="gas/prepayment/uniflo-smart-gas-meter" element={<UnifloSmartGasMeter />} />
            <Route path='gas/prepayment/libra-110' element={<LandisAndGyr110 />} />
            <Route path='gas/prepayment/landis-and-gyr-350' element={<LandisAndGyr350 />} />
            <Route path='gas/prepayment/landis-and-gyr-470' element={<LandisAndGyr470 />} />
            <Route path='gas/prepayment/landis-and-gyr-370' element={<LandisAndGyr370 />} />
            <Route path='gas/prepayment/landis-and-gyr-210' element={<LandisAndGyr210 />} />
            {/* Smart Gas Meter */ }
            <Route path="gas/smartmeter/sciflow-smart-gas-meter" element={<ScifloSmartGasMeter />} />
            <Route path="gas/smartmeter/uniflo-smart-gas-meter" element={<UnifloSmartGasMeter />} />
            <Route path='gas/smartmeter/landis-and-gyr-350' element={<LandisAndGyr350 />} />
            <Route path='gas/smartmeter/landis-and-gyr-470' element={<LandisAndGyr470 />} />
            <Route path='gas/smartmeter/landis-and-gyr-370' element={<LandisAndGyr370 />} />
            <Route path='gas/smartmeter/elster-themis-alpha' element={<ElsterThemisAlpha />} />
            {/* Dumb Gas Meters */}
            <Route path="gas/dumbgasmeter/bobi" element={<Bobi />} />
            <Route path="gas/dumbgasmeter/qk2000" element={<QK2000 />} />
            <Route path="gas/dumbgasmeter/siemens-e6" element={<SiemensE6 />} />
            <Route path="gas/dumbgasmeter/g4diaphragm" element={<G4Diaphragm />} />
            <Route path="gas/dumbgasmeter/landis-and-gyr-g130" element={<LandisAndGyr130 />} />
            <Route path="gas/dumbgasmeter/nuovo-pignone" element={<NuovoPignone />} />
            <Route path="gas/dumbgasmeter/sensusu6" element={<SensusU6 />} />
          {/* Meter Boxes */}
            <Route path="/meter-boxes" element={<SplashMeterBox />} />
            {/* Surface Boxes */}
            <Route path="/meter-boxes/gas-surface-box/surfacebox" element={<SurfaceBox />} />
            {/* Multi Boxes */}
            <Route path="/meter-boxes/gas-multi-box/multibox" element={<MultiBox />} />
            <Route path="/meter-boxes/gas-multi-box/unibox" element={<Unibox />} />
            {/* Cavity Box */}
            <Route path="/meter-boxes/gas-cavity-box/gas-cavity-box" element={<CavityBox />} />
            {/* Semi Concealed */}
            <Route path="/meter-boxes/gas-semi-concealed/mitras-connect" element={<MitrasConnect />} />
            <Route path="/meter-boxes/gas-semi-concealed/mitras" element={<Mitras />} />
            <Route path="/meter-boxes/gas-semi-concealed/national-grid" element={<NationalGridSemiCon />} />
            <Route path="/meter-boxes/gas-semi-concealed/igt" element={<IGT />} />
          {/* Privacy Page */}
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/about" element={<About />} />
          {/* Blank Pages, places I don't want a 404 to show */}
          <Route path="/gas/prepayment" element={<BlankPage />} />
          <Route path="/gas/smartmeter" element={<BlankPage />} />
          <Route path="/gas/dumbgasmeter" element={<BlankPage />} />
          <Route path="/meter-boxes/gas-surface-box" element={<BlankPage />} />
          <Route path="/meter-boxes/gas-multi-box" element={<BlankPage />} />
          <Route path="/meter-boxes/gas-cavity-box" element={<BlankPage />} />
          <Route path="/meter-boxes/gas-semi-concealed" element={<BlankPage />} />
          {/* Catch all routes, this will capture 404s */}
          <Route path="*" element={<CatchAllRoutes />} />
        </Routes>
      </div>
        <Footer />
    </Router>
  );
}

export default App;

function CatchAllRoutes() {
  return (
    <div>
      404
    </div>
  );
}
import '../../page.css'

export default function LandisAndGyr130() {
    const mainImageURL = '/images/meters/g130.png';

    return (
        <div className="page-container">
        <section className="hero-section">
            <img src={mainImageURL} alt="Landis & Gyr G130 Gas Meter" className="main-image" />
        </section>
    
        <section className="content-section">
            <h1>Landis & Gyr G130 Gas Meter</h1>
            <p>
            The Landis & Gyr G130 is a small electronic gas meter with a digital backlit display, internal shut-off valve, factory fitted battery (minimum 10-year life, compared to the 20-year battery life of the Elster smart meter) and 1″ BS 746 connections.
            </p>
            <h2>Dumb Meter With Smart Potential</h2>
            <p>
            The G130 module offers simple credit meter operation much the same as a Sensus U6 but is a digital meter and is a “Smart Ready” format that enables future upgrades without removing or exchanging the meter.  Although it seems that none of the UK gas suppliers are making use of this feature.  So the G130 is not a smart meter, but a dumb meter that just has a digital display.  
            </p>
            <p>
            Whilst certainly not as robust as the likes of a Sensus U6, or even a Nuovo Pignone meter, the G130 does stand up well against other digital meters and is simple to use.
            </p>
            <p>
            This is an ideal wall cavity meter and whilst it doesn’t have the functionality, it looks modern and stylish.  Whilst not perfect for a semi-concealed gas meter box, the Landis & Gyr G130 Gas Meter will fit in one.  However, in a semi-concealed box, it is best if fitted on a bracket with a slight angle to make reading easier.  One draw back to this, is that if the meter is in direct sunlight it can be hard to see the screen, so meter readings at night!
            </p>
        </section>

        <section className="meter-reading-section">
            <h2>Reading A Landis & Gyr G130</h2>
            <p>If you require a meter reading, and the screen is blank, then press and release the red button (button A). You will see a “please wait” message, wait for 30 seconds and the meter index (meter reading) will be displayed..</p>
        </section>

        <section className="content-section">
            <h2>FAQ</h2>
            <h3>We have blank screen and cannot give meter readings and are now being forced to have a smart meter installed. Is there any way the battery can be replaced. It was only fitted in 2020</h3>
            <p>This is a tricky question with all Landis & Gyr meters, and most smart meters.  Yes the battery can be replaced, but they don't make it easy to do so.  If it was easy to remove the battery then people would try removing the battery as a way of getting out of the meter recording their gas usage.</p>
            <p>
            So, to replace the battery the meter would need to be removed from the wall, and the battery replaced.  This is not something that can be done by the homeowner, and would need to be done by a gas engineer.  If your supplier/transporter has paid for a gas engineer to come out, then they will usually just replace the whole meter.
            </p>
        </section>

        </div>
    );
};

